import * as React from "react";
import * as _ from "lodash";
import OrderLifeCycleState from "../../Constants/orderLifeCycleState";
import SendMetrics from "../../CourierService/SendMetrics";
import { useFormDispatch, useFormState } from "./ActivationForm/Context/ActivationFormContext";
import {
  fetchCreateTenancy,
  fetchOrders,
  fetchPutCreateTenancy,
  fetchTenlsFeatureFlags,
  getFeatureFlag,
  isInAllowedListedTokens,
  jwt2Obj
} from "../../CourierService/FetchService";
import SendLogs from "../../CourierService/SendLogs";
import {env} from "../../config/apiConfig";

const ActivationPageMainContainer: React.FC<{children: any}> = ({children}) => {
  const dispatch = useFormDispatch();
  const {
    loading,
    loadingFeatureFlags,
    nonAuth,
    nonPending,
    processing,
    fawError,
    render,
    showThankYou,
    showBanner,
    submitting,
    homeRegion,
    tenancyName,
    emailAddress,
    userName,
    confirmPassword,
    firstName,
    lastName,
    tokenHasExpired,
    tokenLowerCase,
    user_session,
    domainType,
    featureFlags,
    call2tenls,
    upstreamEnvironment,
    orderType,
    partyName,
    alternatePartyName,
    alternateEnglishName,
    customerTypePublicOffer,
    customerPhoneNumber,
    address,
    putting,
    realmSpecificToken
  } = useFormState();

  //Get JWT toke from url query
  const token = new URLSearchParams(window.location.search).get('activationToken');
  const [apiError, setApiError] = React.useState(false);
  const hasTokenBeenChanged = !token || (token === token.toLowerCase());

  const isTenLSEnabled = (token: string) => {
    switch (env) {
      case 'dev':
      case 'uat':
      case 'uat2':
      case 'prd':  return isInAllowedListedTokens(token);
      case 'stg':
      default:
        return true;
    }
  }

  React.useEffect(() => {
    if (apiError) {
      dispatch({
        type:'UPDATE_APIERROR',
        payload: 'boolean',
        boolean: true
      });
      dispatch({
        type: "SUBMIT_CREATE_TENANCY_INFO",
        payload: "boolean",
        boolean: false,
      });
      SendMetrics({name:"ActivationSubmit.Count.Metrics.Error", value:1, userSession:user_session});
      SendLogs({severity: 20000, message: "Activation Submit Error", userSession:user_session});
      }
  }, [apiError, dispatch, user_session]);

  React.useEffect(() => {
    if (token && loadingFeatureFlags) {
      fetchTenlsFeatureFlags(token)
          .then(async (res) => {
            if (res.status === 200) {
              const result = await res.json()
              dispatch({ type: 'SET_FEATURE_FLAGS', payload: 'object', object: result.featureFlag });
              if (isTenLSEnabled(token)) {
                dispatch({ type: 'UPDATE_CALL_2_TENLS', payload: 'object', boolean: getFeatureFlag('enableTenlsOrderActivation', result.featureFlag) })
              }
            }
          })
          .catch((error) => {
            console.log(error);
          }).finally(() => {
            dispatch({ type: 'GETTING_FEATURE_FLAGS', payload: 'boolean', object: false });
          });
    }
  }, [dispatch, render, token, call2tenls, featureFlags, loadingFeatureFlags]);

  React.useEffect(() => {
    //token must exist (not null)
    if (token && loading && !loadingFeatureFlags) {
      fetchOrders(token, call2tenls)
      .then(async res => {
        if(res.status === 200) {
          //set default form values
          const result = await res.json();
          const single = call2tenls ? result : result.items[0];
          if(single.serviceGroupNames !== null && single.serviceGroupNames.length === 1 && single.serviceGroupNames[0].toUpperCase() === 'ANALYTICSAPP') {
            // Here we used to turn on FAW error in global state and send additional fail metrics
            SendMetrics({name:"ActivationOrder.Count.Metrics.FAWOrder", value:1, userSession:user_session});
          }
          const isAlloyOrder = _.get(jwt2Obj(token), 'version', '').toUpperCase() === 'V2';
          dispatch({
            type: 'UPDATE_ALLOY_STATUS',
            payload: 'boolean',
            boolean: isAlloyOrder
          });
          dispatch({
            type: 'PLACE_DEFAULTS',
            payload: "object",
            object: single
          });
          dispatch({
            type: 'UPDATE_PUBLIC_OFFER',
            payload: 'boolean',
            boolean: _.isString(single.upstreamEnvironment) &&
                ['ORP_AZURE', 'ODBG_GCP'].includes(single.upstreamEnvironment.toUpperCase()) &&
                _.isString(single.orderType) &&
                single.orderType.toUpperCase() === 'PUBLIC'
          });
          if (single.emailAddress && single.emailAddress.length <= 4) {
            dispatch({
              type: 'UPDATE_EMAIL_ADDRESS',
              payload: ''
            });
          }
          SendMetrics({name:"ActivationOrder.Count.Metrics", value:1, userSession:user_session});

          //check order status
          if (single) {
            if(!single.orderState || single.orderState !== OrderLifeCycleState.pendingActivation) {
              dispatch({
                type: 'UPDATE_NONPENDING',
                payload: single.orderState? single.orderState : "boolean",
                boolean: true
              })
            SendMetrics({name:"ActivationOrder.Count.Metrics.NonPending", value:1, userSession:user_session})
            }

            //check if DCR_DESCRIPTION is present
            if(single.dataCenterRegionDesc !== null && single.dataCenterRegionDesc !== undefined) {
              dispatch({
                type: 'UPDATE_REGION_NAME',
                payload: single.dataCenterRegionDesc,
              })
            }
            
            //Check if internal customer type
            if(!isAlloyOrder && single.customerType !== null && single.customerType !== undefined && single.customerType.toUpperCase() === 'INTERNAL') {
              dispatch({
                type: 'UPDATE_INTERNAL_CUSTOMER_TYPE',
                payload: 'boolean',
                boolean: true
              })

              //Update tenancy types
              const tenancyList = [{
                options: [
                  {
                    label: 'Standard Tenancy',
                    value: 'DefaultDomain',
                  },
                  {
                    label: 'Lightweight Domain',
                    value: 'LightWeightDomain'
                  }
                ]
              }];
              dispatch({
                type: 'UPDATE_TENANCY_LIST',
                payload: 'object',
                object: tenancyList
              });
              dispatch({
                type:`UPDATE_DOMAIN_TYPE`,
                payload: "object",
                object: { label: 'Standard Tenancy', value: 'DefaultDomain' }
              });
            }
          }
          dispatch({
            type: 'GETTING_AUTH',
            payload: "boolean",
            boolean: false
          });
        }
        if(res.status >= 400) {
          dispatch({
            type: 'UPDATE_NONAUTH',
            payload: "boolean",
            boolean: true
          });
          dispatch({
            type: 'GETTING_AUTH',
            payload: "boolean",
            boolean: false
          });
          SendMetrics({name:"ActivationOrder.Count.Error", value:1, userSession:user_session});
        }
      }).catch(error => {
        console.log(error);
        dispatch({
          type: 'UPDATE_NONAUTH',
          payload: "boolean",
          boolean: true
        });
        dispatch({
          type: 'GETTING_AUTH',
          payload: "boolean",
          boolean: false
        });
        SendMetrics({name:"ActivationOrder.Count.Metrics.Error", value:1, userSession:user_session});
        SendMetrics({name:"ActivationOrder.Count.Fail", value:1, userSession:user_session});
        SendLogs({severity: 20000, message: "Activation Order Failure" , userSession:user_session});
      })
    } else if (!token) {
      if (hasTokenBeenChanged) {
        dispatch({
          type: 'TOKEN_IS_LOWERCASE',
          payload: "boolean",
          boolean: true
        });
        SendMetrics({name: "ActivationOrder.Count.Metrics.TokenHasBeenChanged", value: 1, userSession: user_session});
        SendMetrics({name: "ActivationOrder.Count.Fail", value: 1, userSession: user_session});
        SendLogs({severity: 20000, message: "Activation Order Failure due to Token Change", userSession: user_session});
      } else {
        dispatch({
          type: 'GETTING_AUTH',
          payload: "boolean",
          boolean: false
        });
        dispatch({
          type: 'UPDATE_NONAUTH',
          payload: "boolean",
          boolean: true
        });
        SendMetrics({name: "ActivationOrder.Count.AuthError", value: 1, userSession: user_session});
      }
    }
  },[dispatch, render, loading, loadingFeatureFlags, nonAuth, token, tenancyName, user_session, tokenLowerCase, fawError, hasTokenBeenChanged, call2tenls, featureFlags]);

  React.useEffect(() => {
    if (submitting && processing && putting === false) {
      // if Public Offer and Azure send address and personal data
      const homeRegionId: string = homeRegion.value.id;
      const body = (_.isString(upstreamEnvironment) && ['ORP_AZURE', 'ODBG_GCP'].includes(upstreamEnvironment.toUpperCase()) && _.isString(orderType) && orderType.toUpperCase() === 'PUBLIC') ?
          JSON.stringify({
            dataRegion: homeRegion.group,
            homeRegion: homeRegion.value.id,
            cloudAccountName: tenancyName,
            adminEmail: emailAddress,
            adminUserName: userName,
            adminPassword: confirmPassword,
            adminFirstName: firstName,
            adminLastName: lastName,
            domainType: domainType.value,
            partyName: customerTypePublicOffer === 'INDIVIDUAL' ? `${firstName} ${lastName}` : partyName,
            alternatePartyName: customerTypePublicOffer === 'INDIVIDUAL' ? (alternateEnglishName !== "" ? alternateEnglishName : `${firstName} ${lastName}`) : alternatePartyName,
            customerType: customerTypePublicOffer,
            customerPhoneNumber: customerPhoneNumber && customerPhoneNumber.length > 4 ? customerPhoneNumber : '',
            address: address
          }) : JSON.stringify({
            dataRegion: homeRegion.group,
            homeRegion: homeRegion.value.id,
            cloudAccountName: tenancyName,
            adminEmail: emailAddress,
            adminUserName: userName,
            adminPassword: confirmPassword,
            adminFirstName: firstName,
            adminLastName: lastName,
            domainType: call2tenls ? 'DEFAULT_DOMAIN' : domainType.value
          });
      fetchCreateTenancy(
  call2tenls ? realmSpecificToken : token,
        body,
        call2tenls,
        homeRegionId
      ).then(async res => {
        if(res.status === 202) {
          dispatch({
            type: "SHOW_THANK_YOU",
            payload: "boolean",
            boolean: true,
          });
          SendMetrics({name:"ActivationSubmit.Count.Metrics", value:1, userSession:user_session});
          SendMetrics({name:"ActivationSubmit.Count.Metrics.Processed", value:1, userSession:user_session});
          SendMetrics({name:"ActivationSubmit.Count.Metrics.Submitted", value:1, userSession:user_session});
          SendMetrics({name:"ActivationOrder.Count.Metrics.successful", value:1, userSession:user_session});
          SendLogs({severity: 20000, message: "Submitting and Processing is successful", userSession:user_session});
        } else {
          if (res.status === 412) {
            const error = await res.json()
            const message = error.message;
            dispatch({type: 'UPDATE_ADDRESS_VALIDATION_MESSAGE', payload: _.replace(message, 'ERROR:', '')});
            setApiError(true);
            SendMetrics({name:"ActivationSubmit.Count.Metrics.AddressValidationError", value:1, userSession:user_session});
            SendLogs({severity: 20000, message: "Activation Submit Failure due to: " + res.status , userSession:user_session});
          } else if (res.status === 400) {
            const error = await res.json();
            const expired = error.message.includes('is in past') || error.message.includes('is in future');
            if (expired) {
              dispatch({
                type: 'ORDER_HAS_EXPIRED',
                boolean: true,
                payload: 'boolean'
              });
              dispatch({
                type: "SUBMIT_CREATE_TENANCY_INFO",
                payload: "boolean",
                boolean: false,
              });
              SendMetrics({name:"ActivationSubmit.Count.Metrics.ExpiredToken", value:1, userSession:user_session});
              SendLogs({severity: 20000, message: "Activation Submit Expired Token", userSession:user_session});
            } else if (error.message.includes('The following are the suggestions based on your input')) {
              dispatch({type: 'UPDATE_ADDRESS_VALIDATION_MESSAGE', payload: _.replace(error.message, 'ERROR:', '')});
              setApiError(true);
              SendMetrics({name:"ActivationSubmit.Count.Metrics.AddressValidationError", value:1, userSession:user_session});
              SendLogs({severity: 20000, message: "Activation Submit Failure due to: " + res.status , userSession:user_session});
            } else {
              setApiError(true);
              SendMetrics({name:"ActivationSubmit.Count.Metrics.TokenNotExpired", value:1, userSession:user_session});
              SendLogs({severity: 20000, message: "Activation Submit Token Not Expired: " + error.message , userSession:user_session});
            }
          } else {
            setApiError(true);
            SendMetrics({name:"ActivationSubmit.Count.Metrics.OtherFailure", value:1, userSession:user_session});
            SendLogs({severity: 20000, message: "Activation Submit Failure due to: " + res.status , userSession:user_session});
          }
        }
      }).catch(error => {
        console.log(error);
        setApiError(true);
        SendMetrics({name:"ActivationSubmit.Count.Metrics.Fail", value:1, userSession:user_session});
        SendLogs({severity: 20000, message: "Activation Submit error: " + error.message , userSession:user_session});
      })
    }
  },[dispatch, submitting, processing, homeRegion, tenancyName, emailAddress, userName, alternateEnglishName,
          confirmPassword, firstName, lastName, token, user_session, domainType, upstreamEnvironment, featureFlags,
          orderType, customerTypePublicOffer, address, alternatePartyName, partyName, customerPhoneNumber, putting,
          realmSpecificToken, call2tenls]);

  React.useEffect(() => {
    if (putting && submitting && processing) {
      const orderOcid = jwt2Obj(token).orderId || jwt2Obj(token).resourceId;
      const homeRegionId: string = homeRegion.value.id;
      if (call2tenls) {
        fetchPutCreateTenancy(token, orderOcid, homeRegionId)
            .then(async res => {
              if (res.status >= 200 && res.status < 400) {
                const result = await res.json();
                dispatch({type: 'UPDATE_REALM_SPECIFIC_TOKEN', payload: result.realmSpecificJwToken});
              }
              dispatch({type: 'UPDATE_PUTTING', payload: "boolean", boolean: false});
            });
      } else {
        dispatch({type: 'UPDATE_PUTTING', payload: "boolean", boolean: false});
      }
    }
  }, [putting, dispatch, call2tenls, homeRegion, token, processing, submitting]);

    SendMetrics({name:"ActivationSubmit.Count.Metrics.DropOff", value:1, userSession:user_session});
    SendLogs({severity: 20000, message: "Activation Submit Drop Off", userSession:user_session});

  return (
    <>
    {!processing && !showThankYou && !nonAuth && !loading && !loadingFeatureFlags && !showBanner && !nonPending && !tokenHasExpired && !tokenLowerCase && !fawError &&
      <div data-testid="activationPageMain" className="col-md-8 col-xs-12 mainContainer">
        {children}
      </div>
    }
    </>
  );
};

export default ActivationPageMainContainer;
